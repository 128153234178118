import axios from 'axios';
import ZionConstants from '../util/ZionConstants';

export default async function ({ route, redirect, $auth, $config }) {
  const path = route.path;
  const maintMode = $config.MAINTENANCE_MODE;
  console.log('maint middleware', maintMode);

  if (path === '/maintenance') {
    if (maintMode === false || maintMode === 'false') {
      return redirect('/');
    } else if (maintMode === 'external') {
      if ($auth.user && $auth.user.roles && $auth.user.roles.includes(ZionConstants.ROLE_EXTERNAL)) {
        console.log('stay on maint page');
      } else {
        return redirect('/');
      }
    }
  } else if (maintMode === true || maintMode === 'true') {
    redirect('/maintenance');
  } else if (maintMode === 'external') {
    console.log('external maint');
     
    if ($auth.user && $auth.user.roles && $auth.user.roles.includes(ZionConstants.ROLE_EXTERNAL)) {
      console.log('redirecting');
      redirect('/maintenance');
    } else {
      console.log('not redirecting');
    }
  }
}
