export default {
  ROLE_EXTERNAL: 'd9b6e25a-c21a-46d4-bda3-baf6552f6efc',
  ROLE_STUDENT: '50239080-48d3-4a5b-89cc-8910724e8b59',
  ROLE_SUPER_ADMIN: 'f26380b7-895b-46ef-b6f4-cae824a5a686',
  ROLE_CLIENT_MANAGER: 'f26380b7-895b-46ef-b6f4-cae824a5a687',
  ROLE_CLIENT_ASSOCIATE: 'f26380b7-895b-46ef-b6f4-cae824a5a688',
  ROLE_AR_MANAGER: 'f26380b7-895b-46ef-b6f4-cae824a5a689',
  ROLE_COMPLIANCE_ADMIN: '86fc71d6-62a5-4aef-a7df-c578e04940c6',
  ROLE_ACCOUNT_MANAGER: '6c2de063-8951-4e2b-8036-f47a84913cf4'
};
